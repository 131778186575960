let baseUrl;
let apiDomain;
let psyUrl;
let erpBaseUrl;
let oldVersionUrl; //旧版商城地址

let api_url = ''

switch(process.env.REACT_APP_SITE) {
  case 'CHI':
    api_url = 'n.m2bchi'
    switch (process.env.REACT_APP_RUNNING_ENV) {
      case "production":
        apiDomain = `https://api.${api_url}.com`;
        baseUrl = `https://api.${api_url}.com/api/v1.0`;
        psyUrl = `https://api.${api_url}.com`;
        erpBaseUrl = `https://api.${api_url}.com/api/v2.0`;

        break;

      default:
        apiDomain = "http://192.168.0.15:81";
        baseUrl = "/api";
        psyUrl = `https://api.${api_url}.com`;
        erpBaseUrl = `https://api.${api_url}.com/api/v2.0`;
        break;
    }
    break;
  case 'LIFESTYLE':
    api_url = 'n.m2blals'
    switch (process.env.REACT_APP_RUNNING_ENV) {
      case "production":
        apiDomain = `https://api.${api_url}.com`;
        baseUrl = `https://api.${api_url}.com/api/v1.0`;
        psyUrl = `https://api.${api_url}.com`;
        erpBaseUrl = `https://api.${api_url}.com/api/v2.0`;

        break;

      default:
        apiDomain = "http://192.168.0.15:81";
        baseUrl = "/api";
        psyUrl = `https://api.${api_url}.com`;
        erpBaseUrl = `https://api.${api_url}.com/api/v2.0`;
        break;
    }
    break;
  case 'HOU':
    api_url = 'n.m2bhou'
    switch (process.env.REACT_APP_RUNNING_ENV) {
      case "production":
        apiDomain = `https://api.${api_url}.com`;
        baseUrl = `https://api.${api_url}.com/api/v1.0`;
        psyUrl = `https://api.${api_url}.com`;
        erpBaseUrl = `https://api.${api_url}.com/api/v2.0`;

        break;

      default:
        apiDomain = "http://192.168.0.15:81";
        baseUrl = "/api";
        psyUrl = `https://api.${api_url}.com`;
        erpBaseUrl = `https://api.${api_url}.com/api/v2.0`;
        break;
    }
    break;
  case 'SEA':
    api_url = 'n.m2bsea'
    switch (process.env.REACT_APP_RUNNING_ENV) {
      case "production":
        apiDomain = `https://api.${api_url}.com`;
        baseUrl = `https://api.${api_url}.com/api/v1.0`;
        psyUrl = `https://api.${api_url}.com`;
        erpBaseUrl = `https://api.${api_url}.com/api/v2.0`;

        break;

      default:
        apiDomain = "http://192.168.0.15:81";
        baseUrl = "/api";
        psyUrl = `https://api.${api_url}.com`;
        erpBaseUrl = `https://api.${api_url}.com/api/v2.0`;
        break;
    }
    break;
  case 'MD':
    api_url = 'n.m2bm'
    switch (process.env.REACT_APP_RUNNING_ENV) {
      case "production":
        apiDomain = `https://api.${api_url}.com`;
        baseUrl = `https://api.${api_url}.com/api/v1.0`;
        psyUrl = `https://api.${api_url}.com`;
        erpBaseUrl = `https://api.${api_url}.com/api/v2.0`;

        break;

      default:
        apiDomain = "http://192.168.0.15:81";
        baseUrl = "/api";
        psyUrl = `https://api.${api_url}.com`;
        erpBaseUrl = `https://api.${api_url}.com/api/v2.0`;
        break;
    }
    break;
  case 'WSAC':
    api_url = 'm2bw'
    switch (process.env.REACT_APP_RUNNING_ENV) {
      case "production":
        apiDomain = `https://api.${api_url}.com`;
        baseUrl = `https://api.${api_url}.com/api/v1.0`;
        psyUrl = `https://api.${api_url}.com`;
        erpBaseUrl = `https://api.${api_url}.com/api/v2.0`;

        break;

      default:
        apiDomain = "http://192.168.0.15:81";
        baseUrl = "/api";
        psyUrl = `https://api.${api_url}.com`;
        erpBaseUrl = `https://api.${api_url}.com/api/v2.0`;
        break;
    }
    break;
  default:
    // api_url = 'test.starhome';
    api_url = 'starhomeone';
    switch (process.env.REACT_APP_RUNNING_ENV) {
      case "production":
        apiDomain = `https://api.${api_url}.com`;
        baseUrl = `https://api.${api_url}.com/api/v1.0`;
        psyUrl = `https://api.${api_url}.com`;
        erpBaseUrl = `https://api.${api_url}.com/api/v2.0`;

        break;
      default:
        apiDomain = "http://192.168.0.15:81";
        baseUrl = "/api";
        psyUrl = `https://api.${api_url}.com`;
        erpBaseUrl = `https://api.${api_url}.com/api/v2.0`;
        break;
    }
  break;
}

const netWorkConfig = {
    baseUrl,
    apiDomain,
    psyUrl,
    erpBaseUrl,
    oldVersionUrl,
    timeout: 30 * 1000,
};

export default netWorkConfig;
