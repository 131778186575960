import { useDispatch } from "react-redux";
import { setMessageInfo } from "../app/appSlice";
import { useFormate } from "./useFormate";
import { useState } from "react";

export const useFormData = () => {
  const [formData, setFormData] = useState({});
  const handleFormDataChange = (data = {}) => {
    setFormData({ ...formData, ...data });
  };
  return { handleFormDataChange, formData, setFormData };
};
export const useResetFormData = () => {
  const [formData, setFormData] = useState({});
  const handleFormDataChange = (data = {}, isClear = false) => {
    // isClear: 判断是否是重新赋值还是数据追加
    if(isClear){
      setFormData(data)
    } else {
      setFormData({ ...formData, ...data });
    }
  };
  return { handleFormDataChange, formData, setFormData };
};

export const useFormCheck = ({ module, initRequiredKeys = [] } = {}) => {
  const { formatMessage, formatMsg } = useFormate(module);
  const dispatch = useDispatch();
  const [errorStatus, setErrorStatus] = useState({});
  const resetErrorStatus = () => {
    setErrorStatus({});
  };
  const handleClearStatus = (key) => {
    if (errorStatus[key]) {
      setErrorStatus({ ...errorStatus, [key]: undefined });
    }
  };
  const checkRequire = (requiredKeys = [], formData) => {
    let errorStatus = {};
    requiredKeys.forEach((item) => {
      let value = formData[item.key];
      let messageId =
        item.type === "input" ? "form.inputEmpty" : item.type === "upload" ? "form.uploadEmpty" : "form.selectEmpty";
      let text = item.langKey ? formatMsg(item.langKey) : formatMessage(item.key);
      if (!value) {
        errorStatus[item.key] = {
          error: true,
          helperText: formatMsg(messageId, { text }),
        };
      }
    });
    return errorStatus;
  };
  const isNotError = (errorStatus = {}) => {
    return Object.keys(errorStatus).length === 0;
  };
  const formCheck = ({ requiredKeys, afterHandler, formData }) => {
    let errorStatus = checkRequire(requiredKeys || initRequiredKeys, formData);
    if (afterHandler) afterHandler(errorStatus);
    if (isNotError(errorStatus)) {
      return true;
    } else {
      setErrorStatus(errorStatus);
      dispatch(setMessageInfo({ message: formatMsg("tips.checkField"), type: "error" }));
      return false;
    }
  };

  return { handleClearStatus, errorStatus, formCheck, resetErrorStatus };
};

export const useCheckInputPirce = () => {
  const { formatMsg } = useFormate();
  const checkInputPrice = (errorStatus, formData, name) => {
    const priceCheck = /(^[1-9](\d+)?(\.\d{1,2})?$)|(^\d\.\d{1,2}$)/g;
    if (!errorStatus[name] && priceCheck.test(formData[name]) === false) {
      errorStatus[name] = {
        error: true,
        helperText: formatMsg("failed.inputPrice"),
      };
    }
    return errorStatus;
  };
  return { checkInputPrice };
};

export const useCheckEmail = () => {
  const { formatMsg } = useFormate();
  const checkEmail = (errorStatus, formData, name) => {
    const checkReg = /^\w+([-+.]\w+)*@\w+([-.]\w+)*\.\w+([-.]\w+)*$/;
    if (!errorStatus[name] && checkReg.test(formData[name]) === false) {
      errorStatus[name] = {
        error: true,
        helperText: formatMsg("failed.inputEmail"),
      };
    }
    return errorStatus;
  };
  return { checkEmail };
};
