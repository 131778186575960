import requestErp from "../../utils/requestErp";

/**
 * @description 获取用户物流数据
 * @returns 
 */
export const getUserData = () => {
  return requestErp.post("/pay/logisticsUserPay", {});
};

/**
 * @description 支付物流订单
 * @param {*} data 
 * @returns 
 */
export const rechargeOffline = (data) => {
  return requestErp.post("/pay/rechargeOffline", data);
};

/**
 * @description 动态获取物流未支付金额
 * @param {*} data 
 * @returns 
 */
export const getOrderFee = (data) => {
  return requestErp.post("/pay/getLogisticsOrderFee", data);
};
