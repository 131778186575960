import React from "react";
import { createRoot } from "react-dom/client";
import { Provider, useSelector } from "react-redux";
import { store } from "./app/store";
import Layout from "./components/Layout";
import { BrowserRouter } from "react-router-dom";
import { ThemeProvider } from "@mui/material/styles";
import CssBaseline from "@mui/material/CssBaseline";
import { RawIntlProvider } from "react-intl";
import { getIntl } from "./lang";
import theme from "./theme";
import "react-dates/initialize";
import "react-dates/lib/css/_datepicker.css";
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";
import "./assets/styles/reset.scss";
import "./assets/styles/global.scss";
import ErrorBoundary from "./components/ErrorBoundary";
const container = document.getElementById("root");
const root = createRoot(container as any);

localStorage.setItem('SITE', process.env.REACT_APP_SITE)
switch (process.env.REACT_APP_SITE) {
  case 'WSAC':
    document.title = "Golden Coast Furniture Inc"
    break;
  case 'MD':
    document.title = "Golden Furniture Inc"
    break;
  case 'SEA':
    document.title = "ANCP HOME LONG Inc"
    break;
  case 'HOU':
    document.title = "G Furniture Inc"
    break;
  case 'LIFESTYLE':
    document.title = "LIFE STYLE FURNITURE INC"
    break;
  case 'CHI':
    document.title = "G Furniture Inc(Chicago)"
    break;
  default:
    document.title = "StarHome-Online Furniture Wholesale Platform"
}

const Main = () => {
  const { activeLang } = useSelector((state: any) => state.app);
  const intl = getIntl(activeLang);
  return (
    <ErrorBoundary>
      <RawIntlProvider value={intl}>
        <BrowserRouter>
          <ThemeProvider theme={theme}>
            <CssBaseline />
            <Layout></Layout>
          </ThemeProvider>
        </BrowserRouter>
      </RawIntlProvider>
    </ErrorBoundary>
  );
};

root.render(
  // <React.StrictMode>
  <Provider store={store}>
    <Main></Main>
  </Provider>
  // </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// reportWebVitals();
