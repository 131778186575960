import { useState, useMemo } from "react";
import { getPrice } from "../../hooks/usePrice";
export const useCoupon = () => {
  const [couponShow, setCouponShow] = useState(false);
  const [activeCoupon, setActiveCoupon] = useState();
  const handleCouponSelect = (item) => {
    setActiveCoupon(item);
    setCouponShow(false);
  };
  const couponProps = {
    open: couponShow,
    onClose: () => setCouponShow(false),
    onChange: handleCouponSelect,
    activeCoupon,
  };
  const openCoupon = () => {
    setCouponShow(true);
  };
  return { activeCoupon, couponProps, openCoupon };
};

export const useKeys = (initType = "self") => {
  const [activeType, setActiveType] = useState(initType);
  const keyName = "goods_sn";
  const classId = "relation_id";
  const classKeys = useMemo(() => {
    return {
      // 商品key
      keyName,
      classId,
      // 分类key
      classKey: "warehouse_id",
      // classKey: activeType === "self" ? "warehouse_id" : "merchant_id",
      // 分类名key
      classTxtKey: "warehouse_name",
      // classTxtKey: activeType === "self" ? "warehouse_name" : "merchant_name",
    };
  }, [activeType]);
  return { keyName, activeType, setActiveType, classKeys };
};

export const useHandleData = (keysObj) => {
  const [showList, setShowList] = useState([]);
  const [classObj, setClassObj] = useState({});
  const { keyName, classKey, classTxtKey ,classId} = keysObj;
  const handleShowList = (sourceData) => {
    let classObj = {};
    sourceData.forEach((sourceItem) => {
      if (sourceItem.purchase_mode == '2') {
        // fob购买
        let item = { ...sourceItem };
        item.showPrice = item.price;
        item.totalPrice = getPrice(item.showPrice * item.amount);
        item.bom_list =
          item.bom_list?.map((bom_item) => {
            let showPrice = bom_item.price;
            let amount = bom_item.amount * item.amount;
            let totalPrice = getPrice(showPrice * amount);
            return { ...bom_item, showPrice, totalPrice, amount };
          }) || [];
        if (classObj[item[classKey]]) {
          classObj[item[classKey]].children.push(item);
        } else {
          classObj[item[classKey]] = { id: item[classKey], name: item[classTxtKey], children: [item] };
        }
      } else {
        // 美国仓购买价格封装
        let item = { ...sourceItem };
        item.showPrice = item.special_offer > 0 ? item.special_offer : item.price;
        item.totalPrice = getPrice(item.showPrice * item.amount);
        item.bom_list =
          item.bom_list?.map((bom_item) => {
            let showPrice = bom_item.special_offer > 0 ? bom_item.special_offer : bom_item.price;
            let amount = bom_item.amount * item.amount;
            let totalPrice = getPrice(showPrice * amount);
            return { ...bom_item, showPrice, totalPrice, amount };
          }) || [];
        if (classObj[item[classKey]]) {
          classObj[item[classKey]].children.push(item);
        } else {
          classObj[item[classKey]] = { id: item[classKey], name: item[classTxtKey], children: [item] };
        }
      }

    });
    setClassObj(classObj);
    let list =
      Object.values(classObj)
        .map((item) => ({ ...item, children: item.children.sort((a, b) => a[keyName].localeCompare(b[keyName])) }))
        .sort((a, b) => a[classKey] - b[classKey]) || [];
    setShowList(list);
  };
  return { showList, classObj, handleShowList, setShowList };
};

export const useSelects = (keysObj, classObj) => {
  const [selects, setSelects] = useState([]);
  const { keyName, classKey,classId } = keysObj;
  const setSelectsBySource = (sourceData) => {
    let newSelects = [...selects];
    sourceData.forEach((item) => {
      const sIndex = newSelects.findIndex((o) => o[keyName] === item[classKey] && o[keyName] === item[keyName]);
      if (sIndex >= 0) newSelects.splice(sIndex, 1, item);
    });
    setSelects(newSelects);
  };
  const handleSelect = (item, check) => {
    let newSelects = [...selects];
    if (check) {
      newSelects.push(item);
    } else {
      let index = newSelects.findIndex((o) => o[keyName] === item[keyName]);
      console.log('index',index)
      newSelects.splice(index, 1);
    }
    setSelects(newSelects);
  };
  const handleSelectAll = (checked) => {
    if (checked) {
      let selects = [];
      Object.values(classObj).forEach((item) => {
        selects.push(...item.children);
      });
      setSelects(selects);
    } else {
      setSelects([]);
    }
  };
  const handleClassSelect = (selectId, checked) => {
    // let newSelects = [...selects];
    // let children = classObj[selectId].children;
    // children.forEach((item) => {
    //   let index = newSelects.findIndex((o) => o[classKey] === item[classKey] && o[keyName] === item[keyName]);
    //   if (index < 0 && checked) {
    //     newSelects.push(item);
    //   } else if (index >= 0 && !checked) {
    //     newSelects.splice(index, 1);
    //   }
    // });
    // setSelects(newSelects);
    if (checked) {
      let selects = [];
      Object.values(classObj).forEach((item) => {
        selects.push(...item.children);
      });
      setSelects(selects);
    } else {
      setSelects([]);
    }
  };
  const itemIsCheck = (product) => {
    // return selects.find((o) => o[classKey] === product[classKey] && o[keyName] === product[keyName]) ? true : false;
    return selects.find((o) => o[classId] === product[classId]) ? true : false;
  };
  const selectStatus = useMemo(() => {
    let classCheck = {};
    let classCheckNum = 0;
    Object.values(classObj).forEach((item) => {
      let _selects = item.children.filter((o) =>
        selects.find((select) => o[classKey] === select[classKey] && o[keyName] === select[keyName])
      );
      let checked = _selects.length === item.children.length;
      let indeterminate = checked ? false : _selects.length !== 0;
      if (checked) classCheckNum++;
      classCheck[item.id] = {
        checked,
        indeterminate,
      };
    });
    let allCheck = Object.values(classObj).length === 0 ? false : classCheckNum === Object.keys(classObj).length;
    let allIndeterminate = allCheck ? false : classCheckNum !== 0;
    return {
      allCheck,
      allIndeterminate,
      classCheck,
    };
  }, [classObj, selects]);
  return {
    selects,
    selectStatus,
    setSelects,
    handleSelect,
    handleSelectAll,
    handleClassSelect,
    itemIsCheck,
    setSelectsBySource,
  };
};
