import qs from "qs";
import axios from "axios";
import networkConfig from "../config/network";
import md5 from "blueimp-md5";
import { store } from "../app/store";
import { setMessageInfo, resetToken } from "../app/appSlice";
import { getIntl } from "../lang/index";
export function createSign(data = {}) {
  let str;
  Object.keys(data)
    .sort()
    .forEach((i) => {
      if (data[i] instanceof Array || data[i] instanceof Object) {
      } else {
        if (!str) str = `${i}=${data[i]}`;
        else str += `&${i}=${data[i]}`;
      }
    });
  // console.log('md5',str)
  return md5(str);
}

function delEmpty(data) {
  let obj = {};
  Object.keys(data).forEach((key) => {
    if (data[key]) {
      obj[key] = data[key];
    }
  });
  return obj;
}

const errorMsg = () => {
  const { getState } = store;
  const { activeLang } = getState().app;
  return getIntl(activeLang).formatMessage({ id: "tips.tokenExpire", defaultMessage: "" });
};

export function getParams(data = {}) {
  const { getState } = store;
  const timestamp = parseInt(new Date().getTime() / 1000) + "";
  const { token, token_erp, activeLang: language, uuid, shop_sn } = getState().app;

  // console.log('token_erp',token_erp)
  data = delEmpty(data);
  let _data = { ...data, timestamp, req_source: "supply", token, token_erp, language, uuid, shop_sn };
  let sign = createSign(_data);
  return { ..._data, sign };
}

function createRequest(url, data = {}, method = "get", config = {}) {
  const { dispatch } = store;
  return new Promise((resolve, reject) => {
    let baseUrl = ''

    baseUrl = config.baseUrl || networkConfig.baseUrl
    
    let _url = baseUrl + url;
    requestFunc(_url, data, method, config, resolve, reject)
  }).catch((e) => {
    // console.log("request error", e, config);
    if (!config.noMessage) {
      let str = typeof e === "object" ? "Something Error" : e;
      dispatch(setMessageInfo({ message: str, type: "error" }));
    }
    return Promise.reject(e);
  });
}

function createInstance(reqObj) {
  let { baseUrl, timeout, cancelFunc = () => { }, headers = {}, ...axiosConf } = reqObj;
  return axios.create({
    baseURL: baseUrl,
    timeout: timeout || networkConfig.timeout,
    headers,
    cancelToken: new axios.CancelToken(cancelFunc),
    ...axiosConf,
  });
}
function requestFunc(url, postData, method, config = {}, resolve, reject) {
  config = {
    headers: {
      "content-type": "application/x-www-form-urlencoded",
      // 设置请求头（分站请求头 不分站不同请求头）
      // "apiKey":"1",
      "token": getParams().token
    },
    ...config,
  };
  let instance = createInstance(config);
  return new Promise((resolve) => {
    if (method === "get") resolve(instance.get(url, { params: getParams(postData) }));
    else if (config.headers["content-type"] === "application/x-www-form-urlencoded") {
      resolve(instance.post(url, qs.stringify(getParams(postData))));
    } else {
      // console.log("postData", JSON.stringify(postData));
      resolve(instance.post(url, postData));
    }
  })
    .then((resp) => {
      let { status, data: respData } = resp;
      if (status === 200) {
        if (config.getResponse) {
          resolve(resp);
        } else {
          let isStr = Object.prototype.toString.call(respData) === '[object String]'
          if (isStr) {
            respData = JSON.parse(respData)
            // console.log('respData', respData)
            let { code, data, msg } = respData;
            if (code === 100) {
              const { dispatch } = store;
              dispatch(resetToken());
              reject(errorMsg());
            } else if (code !== 200 && code !==100) {
              reject(msg);
            } else {
              resolve(data);
            }
          } else {
            let { error_code, data, error_msg, code, msg } = respData;
            if (code === undefined) {
              if (error_code === 10000) {
                const { dispatch } = store;
                dispatch(resetToken());
                reject(errorMsg());
              } else if (error_code !== 0) {
                reject(error_msg);
              } else {
                resolve(data);
              }
            } else {
              // 新接口的状态码 code
              if (code === 100) {
                const { dispatch } = store;
                dispatch(resetToken());
                reject(errorMsg());
              } else if (code !== 200 && code !==100) {
                reject(msg);
              } else {
                resolve(data);
              }
            }
          }

        }
      } else {
        reject(resp.errMsg);
      }
    })
    .catch((e) => {
      // console.log("request error", e, config);
      reject(e?.message);
    });
}

const request = {
  get: (url, data, config = {}) => {
    return createRequest(url, data, "get", config);
  },
  post: (url, data, config = {}) => {
    return createRequest(url, data, "post", config);
  },
};

export default request;
