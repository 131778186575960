import { Add, Remove } from "@mui/icons-material";
import { Stack, TextField, IconButton } from "@mui/material";
import { useEffect, useRef, useState } from "react";
const NumInput = ({ value = 1, min = 1, max, onChange = () => {}, sx,dataAttribute=null }) => {
  // console.log('穿过来的商品类型',value,dataAttribute)
  const [currentVal, setCurrentVal] = useState(value);
  const tempValue = useRef(currentVal);
  const handleInputChange = (event) => {
    tempValue.current = currentVal;
    let result = event.target.value;
    if (result.match(/^(-|\+)?(0|[1-9]\d*)?(\.)?(\d+)?$/)) {
      setCurrentVal(result);
    }
  };
  const handleBlur = (event) => {
    let result = event.target.value;
    const nextNum = Number(result);
    if (result !== "") {
      if (Number.isNaN(nextNum)) {
        result = tempValue.current;
      } else {
        if (max && nextNum > max) result = max;
        if (result < min) result = min;
      }
      setCurrentVal(result);
      onChange(result);
    }
  };
  const handleStep = (value) => {
    onChange(value);
  };
  useEffect(() => {
    setCurrentVal(value);
  }, [value]);
  return (
    <Stack alignItems="center" sx={sx} spacing={0.25}>
      <IconButton
        disabled={currentVal <= min}
        sx={{ border: 1, borderRadius: 0, width: 28, height: 28 }}
        onClick={() => handleStep(value - 1)}
        size="small"
      >
        <Remove fontSize="inherit" />
      </IconButton>
      <TextField
        value={currentVal}
        variant="standard"
        sx={{ width: 50, height: 28 }}
        inputProps={{
          sx: { textAlign: "center", pt: "2px", pb: "3px" },
          onChange: handleInputChange,
          onBlur: handleBlur,
        }}
      ></TextField>
      <IconButton
        sx={{ border: 1, borderRadius: 0, width: 28, height: 28 }}
        disabled={max && currentVal >= max || dataAttribute ===2}
        onClick={() => handleStep(Number(value) + 1)}
        size="small"
      >
        <Add fontSize="inherit" />
      </IconButton>
    </Stack>
  );
};
export default NumInput;
