import request from "../../utils/requestErp";

/**
 * @description 获取地址簿列兵
 * @param {Object} data 
 * @returns 
 */
export const getAddressList = (data) => {
  return request.post("/user/getContractAddressList", data);
}

/**
 * @description 保存地址到地址簿
 * @param {Object} data 
 * @returns 
 */
export const saveAddress = (data) => {
  return request.post("/user/saveContractAddress", data)
}