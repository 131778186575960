import { createIntl, createIntlCache } from "react-intl";

const cache = createIntlCache();

const zh = require(`./${process.env.REACT_APP_SITE || 'LA'}/zh-CN.json`)
const es = require(`./${process.env.REACT_APP_SITE || 'LA'}/es-ES.json`)
const us = require(`./${process.env.REACT_APP_SITE || 'LA'}/en-US.json`)

export const getIntl = (lang) => {
  let intl;
  if (lang === "zh-CN") {
    intl = createIntl({ locale: "zh-CN", messages: zh }, cache);
  }else if(lang === "es-ES") {
    intl = createIntl({ locale: "es-ES", messages: es }, cache);
  }else {
    intl = createIntl({ locale: "en-US", messages: us }, cache);
  }
  return intl;
};
