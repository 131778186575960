import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { fetchProducts, fetchZoneList } from "./api";
import { fetchProductData } from '../../network/productErpApi'

const initialState = {
  params: {
    product_type: "", // 商品类型 (1散件, 2配件, 3套件)默认是全部
    first_available: "0", // 首次上架 0=全部,7=最近7天,14=最近14天,30=最近30天,60=最近60天
    // is_fob: "0",  //原系统字段判断是否fob
    purchase_mode: '', //交易模式: 0表示所有 1.美国仓 2.FOB 3.特价
    sort: "d", //排序条件：u=升序，d=降序(默认)
    sort_type: "stock", //new=新品（默认），price=价格，stock=库存，download=下载
    small_sort: { name: "sort.newArrival", id: "new=>d" },
    is_page: 1, // 0无需分页 1需要分页
    active_name: '', // ''全部 new 新品 hot_sales 热销
    search_date: '', // 根据商品日期筛选
  },
  loading: false,
  listData: [],
  total: 0,
  selectIds: [],
  zoneList: [],
};
export const fetchListAsnyc = createAsyncThunk("category/fetchList", async (data, { getState }) => {
  const { params } = getState().category;
  const isLogined = !!getState().app.token //判断是否登录
  let fetchProductRes = await  fetchProducts({ ...params, ...data })
  //没有登录或者是选择显示fob数据也直接不请求对应库存
  if (!isLogined || params.purchase_mode === '2') { 
    return fetchProductRes
  }
  // let productStockAry = await fetchProductData(fetchProductRes.list)
  // return Object.assign({}, fetchProductRes, { list: productStockAry })
  return Object.assign({}, fetchProductRes, { ... data  })
});
export const fetchZoneListAsync = createAsyncThunk("category/fetchZoneList", async () => {
  return await fetchZoneList();
});
export const categorySlice = createSlice({
  name: "category",
  initialState,
  reducers: {
    setParams: (state, { payload }) => {
      state.params = { ...state.params, ...payload };
    },
    setSelects: (state, { payload }) => {
      state.selectIds = payload;
    },
    resetCategory: (state) => {
      state.params = { ...initialState.params };
      state.selectIds = [];
      state.listData = [];
    },
    setProductHotParams: (state,{ payload }) => {
      state.params.active_name = payload
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchListAsnyc.pending, (state) => {
        state.loading = true;
      })
      .addCase(fetchListAsnyc.rejected, (state) => {
        state.loading = false;
        state.total = 0;
        state.listData = [];
      })
      .addCase(fetchListAsnyc.fulfilled, (state, action) => {
        state.listData = action.payload.list;
        state.total = action.payload.count;
        state.loading = false;
      })
      .addCase(fetchZoneListAsync.fulfilled, (state, action) => {
        state.zoneList = action.payload;
      })
      .addCase(fetchZoneListAsync.rejected, (state) => {
        // console.log('仓库列表请求失败', state)
      })
  },
});

export const { setParams, setSelects, setSearchDate, resetCategory, setProductHotParams  } = categorySlice.actions;
export const selectSelectIds = (state) => state.category.selectIds;
export const selectParams = (state) => state.category.params;
export const selectListObj = (state) => {
  return {
    total: state.category.total,
    listData: state.category.listData,
    loading: state.category.loading,
  };
};
export const selectZoneList = (state) => state.category.zoneList;
export default categorySlice.reducer;
