/**
 * @description 对接erp接口
 */
// v1
import request from "../utils/request";
// 新版ERP接口接入
// v2
import requestErp from "../utils/requestErp";
/**
 * 
 * @description 请求ERP接口
 */
// v1
export const fetchErp = (data = {}) => {
  return request.post("/supply/goods/erp/requestErp", data)
}


// erp中转接口 v2
export const erpRequest = (data) => {
  return requestErp.post("/utils/erpRequest", data)
};
