import request from "../utils/request";
// erp接口请求
import requestErp from "../utils/requestErp";

export const fetchInit = () => {
  return request.post("/supply/operation/shop/list");
};

// 分类模块
// export const fetchMenu = () => {
//   return request.post("/supply/goods/type/select").then(({ list = [] }) => list);
// };
// 新版分类
export const fetchMenu = () => {
  let params = {
    request_address: '/market/getProductCategory',
    method: "get",
    param: JSON.stringify({}),
  }
  return requestErp.post("/utils/erpRequest",params);
};

// export const toLogin = (data) => {
//   return request.post("/supply/member/auth/login", data,);
// };
export const toLogin = (data = {}) => {
  return requestErp.post("/user/login", data,)
}
export const toRegister = (data = {}) => {
  return requestErp.post("/user/register", data, );
};
// export const fetchUserInfo = (data) => {
//   return request.post("/supply/member/user/info",data);
// };
export const fetchUserInfo = (data) => {
  return requestErp.post("/user/info",data,);
};

export const fetchVerify = () => {
  let params = {
    request_address: '/user/getUserCertificationData',
    method: "post",
    param: JSON.stringify({}),
  }
  return requestErp.post("/utils/erpRequest",params,);
};

// 获取账户信息
export const fetchAccount = () => {
  return requestErp.post("/user/accountInfo")
}

// 站点获取接口
export const fetchSiteInfo = (data) => {
  return requestErp.post("/utils/getConfigData",data,);
};


// 格式化日对象
export const  getNowDate=()=>{
  var date = new Date()
  var sign2 = ':'
  var year = date.getFullYear() // 年
  var month = date.getMonth() + 1 // 月
  var day = date.getDate() // 日
  var hour = date.getHours() // 时
  var minutes = date.getMinutes() // 分
  var seconds = date.getSeconds() //秒
  var weekArr = [
    '星期一',
    '星期二',
    '星期三',
    '星期四',
    '星期五',
    '星期六',
    '星期天',
  ]
  var week = weekArr[date.getDay()]
  // 给一位数的数据前面加 “0”
  if (month >= 1 && month <= 9) {
    month = '0' + month
  }
  if (day >= 0 && day <= 9) {
    day = '0' + day
  }
  if (hour >= 0 && hour <= 9) {
    hour = '0' + hour
  }
  if (minutes >= 0 && minutes <= 9) {
    minutes = '0' + minutes
  }
  if (seconds >= 0 && seconds <= 9) {
    seconds = '0' + seconds
  }
  return (
    year +
    '-' +
    month +
    '-' +
    day +
    ' ' +
    hour +
    sign2 +
    minutes +
    sign2 +
    seconds
  )
}
