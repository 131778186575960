import React, { useState, useEffect } from "react";
import { 
  Box,
  Grid,
  Avatar,
  Button,
  Divider,
  Link
} from "@mui/material";
import { useNavigate, useSearchParams } from "react-router-dom";
import MenuList from "./MenuList";
import carIcon from '../../assets/img/logistics/car.png'
import buyCarIcon from '../../assets/img/logistics/buy-cart.png'
import arrowIcon from '../../assets/img/logistics/arrow-red.png'
import cardBg from '../../assets/img/logistics/user-box-bg.png'
import './style/BaseInfo.scss'
import { getOrderFee, getUserData } from './api'
import { useFormate } from "../../hooks/useFormate";
import { useDispatch, useSelector } from "react-redux";
import { setLogisticsData } from '../../app/appSlice'


const BaseInfo = (props) => {
  const navigate = useNavigate()
  const [ params ] = useSearchParams()
  const dispatch = useDispatch();
  const { formatMessage, formatMsg } = useFormate();
  const baseData = useSelector(state => state.app.logisticsData);

  const fetch = async () => {
    // console.log(searchParams.get('order_number'))
    // localStorage.removeItem('logisticsUser')
    const res = await getUserData()
    // console.log(res)
    const data = {
      payDialog: false,
      pay_fee: 0,
      order_number: '',
      ...res.data
    }
    const order_number = params.get('order_number') || params.get('order_sn')
    if(order_number) {
      getOrderFee({
        order_number,
      }).then(res => {
        data.payDialog = true
        data.pay_fee = res.unpaid_fee
        data.order_number = order_number
        dispatch(setLogisticsData(data))
      })
    } else {
      dispatch(setLogisticsData(data))
    }
    
  }

  const backMall = () => {
    // switch (process.env.REACT_APP_SITE) {
    //   case 'WSAC':
    //     document.title = "Golden Coast Furniture Inc"
    //     break;
    //   case 'MD':
    //     document.title = "Golden Furniture Inc"
    //     break;
    //   case 'SEA':
    //     document.title = "ANCP HOME LONG Inc"
    //     break;
    //   case 'HOU':
    //     document.title = "G Furniture Inc"
    //     break;
    //   case 'LIFESTYLE':
    //     document.title = "LIFE STYLE FURNITURE INC"
    //     break;
    //   case 'CHI':
    //     document.title = "G Furniture Inc(Chicago)"
    //     break;
    //   default:
    //     document.title = "StarHome-Online Furniture Wholesale Platform"
    // }
    navigate("/user_center/home_page")
    switch (localStorage.getItem('SITE')) {
      case 'WSAC':
        document.title = "Golden Coast Furniture Inc"
        break;
      case 'MD':
        document.title = "Golden Furniture Inc"
        break;
      case 'SEA':
        document.title = "ANCP HOME LONG Inc"
        break;
      case 'HOU':
        document.title = "G Furniture Inc"
        break;
      case 'LIFESTYLE':
        document.title = "LIFE STYLE FURNITURE INC"
        break;
      case 'CHI':
        document.title = "G Furniture Inc(Chicago)"
        break;
      default:
        document.title = "StarHome-Online Furniture Wholesale Platform"
    }
  }

  useEffect(() => {
    fetch()
  }, [params.get('order_number'), params.get('order_sn')])
  return (
    <React.Fragment>
      <Box className="base_info">
        <Box sx={{padding: '36px 24px'}}>
          <Box className="title">
            <img src={carIcon} alt="" className="car_icon" />
            <Box>{formatMsg('logistics.base.title')}</Box>
          </Box>
          <Divider textAlign="center" sx={{margin: '32px auto 0', width:'140px', backgroundColor: '#E1E1E1'}}/>
          <Box className="card_box" sx={{backgroundImage: 'url(' + cardBg + ')'}}>
            <Grid container className="info_card">
              <Grid item>
                <Avatar className="avatar_box"></Avatar>
              </Grid>
              <Grid item className="info_card_right">
                <Box className="info_card_name">{baseData.nickname}</Box>
                <Box className="info_card_phone">{baseData.account}</Box>
              </Grid>
            </Grid>
            <Link className="link_btn" onClick={backMall}>
              <img src={buyCarIcon} alt="" className="buy_car_icon" />
              {formatMsg('logistics.userCenter')}
              <img src={arrowIcon} alt="" className="arrow_icon" />
            </Link>
          </Box>
        </Box>
        <MenuList></MenuList>
      </Box>
    </React.Fragment>
  );
};

export default BaseInfo;
