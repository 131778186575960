import { Box, Container, Grid, Stack, Typography, Link, Divider } from "@mui/material";
import { useEffect, useState } from "react";
import { fetchFollowUs } from "./api";
import { useNavigate } from "react-router-dom";
import brand1 from "../../assets/img/brand1.png";
import brand2 from "../../assets/img/brand2.png";
import zhifuAlipay from "../../assets/img/zhifuAlipay.png";
import zhifuDiscover from "../../assets/img/zhifuDiscover.png";
import zhifuMaster from "../../assets/img/zhifuMaster.png";
import zhifuPaypal from "../../assets/img/zhifuPaypal.png";
import zhifuVisa from "../../assets/img/zhifuVisa.png";
import zhifuWeichatpay from "../../assets/img/zhifuWeichatpay.png";
import zhifuYuntong from "../../assets/img/zhifuYuntong.png";
import { useFormate } from "../../hooks/useFormate";
import useBreakPoint from "../../hooks/useBreakPoint";

const articleList = [
  "about",
  // "contact_us",
  // "adding_account_funds",
  "marketplace_policy",
  // "terms_conditions",  条款、条件  
  "returns_warranty",
  // "about_fob_policy",
  // "fob_returns_warranty",
];

// const contactUsList = ["contact_us", "help_center"];
const contactUsList = ["contact_us", "help_center"];

const Footer = () => {
  const { breakpoints } = useBreakPoint();
  const { formatMessage } = useFormate("footer");
  const [followUs, setFollowUs] = useState([]);
  const navigate = useNavigate();
  const toHelpCenter = (pageKey) => {
    navigate(`/help_center${pageKey ? "?pageKey=" + pageKey : ""}`);
  };
  const toNewPage = (url) => {
    if (url) window.open(url, "_blank");
  };
  useEffect(() => {
    // fetchFollowUs().then((data) => {
    //   setFollowUs(data.list || []);
    // });
  }, []);
  return (
    <Box className="component-footer" sx={{ background: "#333333", pt: 4,color:"#fff",}}>
      <Container sx={(theme) => ({ [theme.breakpoints.down("md")]: { px: 1 } })} >
        <Grid container justifyContent="space-around" spacing={2} >
          <Grid item xs={6} lg="auto" >
            <Typography variant="h5" component="div" fontWeight={500} fontFamily={"Konnect"} marginBottom={2}>
              {formatMessage("brand")}
            </Typography>
            <Stack direction={"column"} spacing={1} sx={{ width: "100%" }}>
              <img src={brand1} alt="" style={{ width: 163, height: 44 }}></img>
              {/* 跳转商城地址 */}
              <Link href="https://www.ainehome.com">
                <img src={brand2} alt="" style={{ width: 163, height: 44 }}></img>
              </Link>
            </Stack>
          </Grid>
          {/* 关于我们 */}
          <Grid item xs={6} lg="auto">
            <Typography variant="h5" component="div" fontWeight={500} fontFamily={"Konnect"}  marginBottom={2} >
              {formatMessage("aboutUs")}
            </Typography>
            <Stack direction="column" spacing={1}>
              {articleList.map((item, index) => (
                <Link
                  key={index}
                  onClick={() => toHelpCenter(item)}
                  variant="body2"
                  component="div"
                  sx={{
                    lineHeight: "24px",
                    color: "#999999",
                    cursor: "pointer",
                    ":hover": {
                      color: "#fff",
                    },
                  }}
                >
                  {formatMessage(item)}
                </Link>
              ))}
            </Stack>
          </Grid>
          {/* 客户服务 */}
          <Grid item xs={6} lg="auto">
            <Typography variant="h5" component="div" fontWeight={500} fontFamily={"Konnect"} marginBottom={2}>
              {formatMessage("customer")}
            </Typography>
            <Stack direction="column" spacing={1}>
              <Stack direction="column" spacing={1}>
                {contactUsList.map((item, index) => (
                  <Link
                    key={index}
                    onClick={() => toHelpCenter(item === "help_center" ? "" : item)}
                    variant="body2"
                    component="div"
                    sx={{
                      color: "#999999",
                      lineHeight: "24px",
                      cursor: "pointer",
                      ":hover": {
                        color: "#fff",
                      },
                    }}
                  >
                    {formatMessage(item)}
                  </Link>
                ))}
              </Stack>
            </Stack>
          </Grid>
          {/* 关注我们   ml="auto"*/}
          <Grid item xs={6} lg="auto">
            <Typography variant="h5" component="div" fontWeight={500} marginBottom={2} fontFamily={"Konnect"}>
              {formatMessage("follow")}
            </Typography>
            <Stack spacing={1} alignItems="center" flexWrap="wrap">
              {followUs.map((item, index) => (
                <img
                  style={{ cursor: "pointer" }}
                  onClick={() => toNewPage(item.url)}
                  width={24}
                  key={index}
                  src={item.file_path}
                  alt=""
                ></img>
              ))}
            </Stack>
            <Typography variant="h5" component="div" fontWeight={500} marginTop={3} marginBottom={2} fontFamily={"Konnect"}>
              {formatMessage("accept")}
            </Typography>
            {/* 下面图片 */}
            <Stack spacing={0.5} flexWrap="wrap" sx={{ img: { mb: "8px !important" }, mb: -1 }}>
              {/* <img alt="" src={zhifuDiscover}></img> */}
              {/* <img alt="" src={zhifuMaster}></img> */}
              <img alt="" src={zhifuPaypal}></img>
              <img alt="" src={zhifuVisa}></img>
              {/* <img alt="" src={zhifuYuntong}></img> */}
              <img alt="" src={zhifuWeichatpay}></img>
              <img alt="" src={zhifuAlipay}></img>
            
            </Stack>
          </Grid>
        </Grid>
        <Divider sx={(theme) => ({ mt: 3, [theme.breakpoints.down("md")]: { mt: 1 } })}></Divider>
      
      </Container>
      {/* 下面页脚描述 */}
        <Stack
          spacing={1}
          direction={"row"}
          divider={<Divider orientation="vertical" flexItem />}
           
          sx={(theme) => ({ py: 2, [theme.breakpoints.down("md")]: { pt: 1, flexDirection: "column",} })}
          justifyContent={"center"}
          backgroundColor={"#1A1A1A"}
        
        >
          <Typography variant="body2" fontSize={12} component="span" color="#ffffff" fontFamily={"Konnect"}>
            {formatMessage("copyright")}
          </Typography>
          <Typography variant="body2" fontSize={12} component="span" color="#ffffff" fontFamily={"Konnect"} >
           |
          </Typography>
          <Typography variant="body2" fontSize={12} component="span" color="#ffffff" fontFamily={"Konnect"}>
            {formatMessage("address")}
          </Typography>
          <Typography variant="body2" fontSize={12} component="span" color="#ffffff" fontFamily={"Konnect"}>
           |
          </Typography>
          <Typography variant="body2" fontSize={12} component="span" color="#ffffff" fontFamily={"Konnect"}>
            {formatMessage("email")}
          </Typography>
        </Stack>
    </Box>
  );
};

export default Footer;
