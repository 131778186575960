import { Box } from "@mui/material";
const ImgBox = ({ alt, title, src, width, height, sx, disabledScale, disabledPointer, ...props }) => {
  return (
    <Box
      {...props}
      sx={{
        cursor: disabledPointer ? "auto" : "pointer",
        width: width || "100%",
        height: height || "100%",
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        // backgroundImage: `url(${src})`,
        // backgroundRepeat: "no-repeat",
        // backgroundPosition: "center",
        // backgroundSize: "100%",
        mx: "auto",
        overflow: "hidden",
        img: {
          transform: "scale(1)",
          transition: "transform 0.1s linear",
        },
        ":hover img": disabledScale
          ? {}
          : {
              transform: "scale(1.05)",
            },
        ...sx,
      }}
    >
      <img
        style={{ maxWidth: "100%", maxHeight: "100%", width: "auto", height: "auto", objectFit: "scale-down" }}
        alt=""
        className="image-view"
        src={src}
      ></img>
    </Box>
  );
};

export default ImgBox;
