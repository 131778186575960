import { useState } from "react";
import { DateRangePicker, SingleDatePicker } from "react-dates";
import { useFormate } from "../../hooks/useFormate";
const createId = () => `date-input-${parseInt(Math.random() * Math.pow(10, 10))}`;

// 开始时间到结束时间
export const RangePicker = ({ onChange = () => {}, small, ...props }) => {
  const { formatMsg } = useFormate()
  const [ids] = useState({ start: createId(), end: createId() });
  const [startDate, setStartDate] = useState();
  const [endDate, setEndDate] = useState();
  const [focusedInput, setFocusedInput] = useState();
  const handleChange = (data) => {
    setStartDate(data.startDate);
    setEndDate(data.endDate);
  };
  const handleClose = (data) => {
    onChange([data.startDate, data.endDate]);
  };
  return (
    <DateRangePicker
      startDate={startDate} // momentPropTypes.momentObj or null,
      startDateId={ids.start} // PropTypes.string.isRequired,
      endDate={endDate} // momentPropTypes.momentObj or null,
      endDateId={ids.end} // PropTypes.string.isRequired,
      onDatesChange={handleChange}
      readOnly
      focusedInput={focusedInput} // PropTypes.oneOf([START_DATE, END_DATE]) or null,
      onFocusChange={(focusedInput) => setFocusedInput(focusedInput)} // PropTypes.func.isRequired,
      onClose={handleClose}
      isOutsideRange={() => false}
      small
      numberOfMonths={1} 
      showClearDates
      hideKeyboardShortcutsPanel
      reopenPickerOnClearDates
      startDatePlaceholderText={formatMsg('starttime')}
      endDatePlaceholderText={formatMsg('endtime')}
      {...props}
    />
  );
};

// 单独日期
export const DatePicker = ({ onChange = () => {}, ...props }) => {
  const [date, setDate] = useState();
  const [focusedInput, setFocusedInput] = useState();
  const [id] = useState(createId());
  const handleClose = (data) => {
    onChange(data.date);
  };
  return (
    <SingleDatePicker
      onDateChange={(date) => setDate(date)}
      readOnly
      small
      date={date}
      focused={focusedInput}
      onFocusChange={({ focused }) => setFocusedInput(focused)}
      id={id}
      onClose={handleClose}
      showDefaultInputIcon
      inputIconPosition="after"
      showClearDate
      isOutsideRange={() => false}
      reopenPickerOnClearDate
      {...props}
    ></SingleDatePicker>
  );
};
