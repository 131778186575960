import { AddShoppingCart, LocalShippingOutlined, DeleteOutline, CloseOutlined } from "@mui/icons-material";
import {
  Box,
  Checkbox,
  Grid,
  Typography,
  Fab,
  Button,
  SwipeableDrawer,
  Stack,
  IconButton,
  FormControlLabel,
  CircularProgress,
  Badge,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Autocomplete,
  TextField
} from "@mui/material";
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import React, { useEffect, useState, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useSearchParams } from "react-router-dom";
import { selectCurrency, selectIsLogin, setMessageInfo } from "../../app/appSlice";
import { useFormate } from "../../hooks/useFormate";
import {
  selectCartList,
  getCartListAsync,
  changeCartItem,
  delCartItemAsync,
  createOrderAsync,
  selectCartLoadig,
  selectCartProductNum,
  addCartItem, //重新添加购物车
  getTopCartListAsync,  //fob  redux方法 请求数据
  getContactCartListAsync, //合同商品
} from "../../pages/Cart/cartSlice";
import { useKeys, useHandleData, useSelects } from "../../pages/Cart/hooks";
import { useCalcCartPrice, useCartPriceObj } from "../../hooks/usePrice";
import ContractAddressDialog from "../../pages/Product/components/ContractAddressDialog";
import Empty from "../Empty";
import ImgBox from "../ImgBox";
import NumInput from "../NumInput";
import { store } from "../../app/store";
import caetImg from '../../assets/img/cart.png';
import testImage from '../../assets/images/F32113.png';
import './index.scss'
// 创建fob订单
import { getContractGoods, getAreaList } from "../../pages/Cart/api";
const activeKeys = ["self", "freight"];
const activeKeys2 = ["self", "ship"];
const FloatCart = () => {
  const { formatMessage, formatMsg } = useFormate("cart");
  const [drawerShow, setDrawerShow] = useState(false);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const currency = useSelector(selectCurrency);
  const loading = useSelector(selectCartLoadig);
  const isLogin = useSelector(selectIsLogin);
  const cartList = useSelector(selectCartList);
  const { getPriceObj } = useCartPriceObj();
  const { calcCartPrice } = useCalcCartPrice();
  const { activeType, classKeys, setActiveType } = useKeys();
  const { showList, classObj, handleShowList } = useHandleData(classKeys);
  const [searchParams] = useSearchParams();
  //订单数据
  const [orderData, setOrderData] = useState({
    open: false, //是否打开
    select_ids: [], //商品选择的ids
    merchant_uuid: '', //商户id
  });
  // 是否是包邮用户
  const isFreight = localStorage.getItem("is_freight");
  // 默认传数据的数据
  const [paramData, setParamData] = useState({
    warehouse_area_id: '1',  //发货地址id
    purchase_mode: '1', //商品交易模式
    source_type: 1, //商品类型  1美国 2fob
    logistics_type: isFreight == 2 ? 3 : 1, //物流方式 1.自提 2.送货 3.包邮
  })
  const cartProductNum = useSelector(selectCartProductNum);
  const { selects, selectStatus, setSelects, handleSelect, handleClassSelect, itemIsCheck, setSelectsBySource } =
    useSelects(classKeys, classObj);
  // redux  筛选数据
  const getList = (postData) => {
    // 获取购物车数据
    dispatch(getCartListAsync(postData));
  };
  // 用户类型（内部 外部）
  const { getState } = store;
  const { internal_user } = getState().app;

  // 不是fob 商品添加数据
  const handleNumChange = (val, info) => {
    setSelects([]);
    let goods_list_Str = [{
      product_id: info.product_id,
      relation_id: info.relation_id,
      preview: info.preview,
      amount: val,
      goods_sn: info.goods_sn,
      custom_sn: info.purchase_mode,
    }]
    let params = {
      warehouse_area_id: info.warehouse_id,
      source_type: info.source_type,
      logistics_type: info.logistics_type,
      purchase_mode: info.purchase_mode,
      merchant_uuid: info.merchant_uuid,
      goods_list: JSON.stringify(goods_list_Str)
    };
    dispatch(addCartItem(params)).then((data) => {
      if (!data.error) {
        getList(paramData);
      }
    });
  };
  // 合同商品数据
  const [contactLsit, setContactList] = useState([])
  const [selectsList, setSelectsList] = useState([])

  const [expanded, setExpanded] = useState(false);
  // 当前只能选择某一个商家
  const handleChangeAccordion = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
    setSelectsList([])
  };
  // 当前选中的
  const handleSelectItem = (checked, item, index) => {
    let selects = [...selectsList]
    if (checked) {
      selects.push(item)
      setSelectsList(selects)
    } else {
      let del_index = selects.findIndex(select => select.id === item.id)
      selects.splice(del_index, 1)
      setSelectsList(selects)
    }
  }

  // 定义的列表
  const [warehouseAreaArry, setWarehouseAreaArry] = useState([])
  // 商品类型
  const goodsArry = [
    // { id: "0", name: "All" },
    { id: "1", name: "General" },
    { id: "2", name: "Special" },
  ];
  const [params, setParams] = useState({ areaAdress_id: warehouseAreaArry[0] });
  //  下拉筛选
  const handleSelectWare = (data = {}) => {
   
    setSelects([]);
    setParams({ ...params, ...data });
    // 设置选择的仓库id 
    let wareHoseData = { ...paramData }
    wareHoseData.warehouse_area_id = data.areaAdress_id.id
    setParamData(wareHoseData)
    let postParam = {
      warehouse_area_id: data.areaAdress_id.id,
      purchase_mode: wareHoseData.purchase_mode,
      source_type: wareHoseData.source_type,
      logistics_type: wareHoseData.logistics_type,
    }
    getList(postParam)
  };

  // 选择商品类型handleSelectGoodsType
  const [paramsGoods, setParamsGoods] = useState({ goos_type: goodsArry[0] });
  //  下拉筛选
  const handleSelectGoodsType = (data = {}) => {
    setSelects([]);
    setParamsGoods({ ...params, ...data });
    // 设置选择的仓库id 
    let goodsData = { ...paramData }
    goodsData.purchase_mode = data.goos_type.id
    setParamData(goodsData)
    let postParam = {
      warehouse_area_id: goodsData.warehouse_area_id,
      purchase_mode: data.goos_type.id,
      source_type: goodsData.source_type,
      logistics_type: goodsData.logistics_type,
    }
    getList(postParam)

  };

  // 获取提货地址列表
  const handleGetAreaList = () => {
    getAreaList().then((res) => {
      if (res) {
        let firstData = { ...params }
        firstData.areaAdress_id = res.list[0]
        setParams(firstData)
        let firstFormData = { ...paramData }
        firstFormData.warehouse_area_id = res.list[0].id
        setParamData(firstFormData)
        setWarehouseAreaArry(res.list)
        getDataList(res.list[0].id)
      }
    })
  }

  const handlePayment = () => {
    const goods_list = []
    // 选中那些商品数据
    selects.forEach((item) => {
      goods_list.push({
        product_id: item.product_id,
        relation_id: item.relation_id,
        quantity: item.amount,
      })
    })
    const params = {
      logistics_type: String(isFreight == 2 ? 3 : showList[0].children[0].logistics_type),//美国仓商品物流方式：1.自提 2.平台送货 默认为0 3.包邮用户
      purchase_mode: String(showList[0].children[0].purchase_mode), // 美国仓交易模式：1.普通 2.特价 3.处理 4.待定 5.（其他待定...）默认为0
      warehouse_area_id: String(showList[0].children[0].warehouse_id), //区域id
      cart_id: String(showList[0].children[0].cart_id), //购物车id
      source_type: String(showList[0].children[0].source_type), //M F 
      product_list: JSON.stringify(goods_list), //里面包含商品id和组件id还有购物车列表id
    }
    dispatch(createOrderAsync(params)).then((data) => {
      if (!data.error) {
        setDrawerShow(false);
        navigate(`/pay?order_sn=${data.payload.order_parent_number}`);
      }
    });
  };

  const handlePaymentFob = () => {
    const ides = selectsList.map(item => item.id)
    setOrderData({
      open: true,
      select_ids: ides,
      merchant_uuid: selectsList[0].merchant_uuid, //默认拿数组下标第一个就是当前商家uuid
    })
  }
  //处理弹窗关闭
  const handleContractClose = (action) => {
    //重新获取数据
    if (action && action === 'clear') { //是否清除选中数据
      setContactList([]) //重置数据进行请求
      setSelectsList([])
    }
    getConactList()
    setOrderData({
      open: false,
      select_ids: [],
      merchant_uuid: ''
    })
  }

  // 特价商品下单模块
  const handlePaymentSpecial = () => {
    const goods_list = []
    showList.forEach((o) => {
      o.children.forEach((item) => {
        goods_list.push({
          product_id: item.product_id,
          relation_id: item.relation_id,
          quantity: item.amount,
        })
      })
    })
    const params = {
      logistics_type: String(isFreight == 2 ? 3 : showList[0].children[0].logistics_type),//美国仓商品物流方式：1.自提 2.平台送货 默认为0
      purchase_mode: String(showList[0].children[0].purchase_mode), // 美国仓交易模式：1.普通 2.特价 3.处理 4.待定 5.（其他待定...）默认为0
      warehouse_area_id: String(showList[0].children[0].warehouse_id), //区域id
      cart_id: String(showList[0].children[0].cart_id), //购物车id
      source_type: 1, //M F 
      product_list: JSON.stringify(goods_list), //里面包含商品id和组件id还有购物车列表id
    }

    dispatch(createOrderAsync(params)).then((data) => {
      if (!data.error) {
        setDrawerShow(false);
        navigate(`/pay?order_sn=${data.payload.order_parent_number}`, { replace: true });
      }
    });
  }


  const handleDel = (item) => {
    let postDelData = {
      product_id: item.product_id,
      relation_id: item.relation_id,
      warehouse_area_id: item.warehouse_id,
      source_type: item.source_type,
      logistics_type: item.logistics_type,
      purchase_mode: item.purchase_mode,
      id: item.id
    }
    // 默认美国仓
    dispatch(delCartItemAsync(postDelData)).then((data) => {
      if (!data.error) {
        let getListData ={
          warehouse_area_id: item.warehouse_id,
          source_type: item.source_type,
          logistics_type: item.logistics_type,
          purchase_mode: item.purchase_mode,
        }
        getList(getListData);
      }
    });
  };

  const handleNewFobDel = (item) => {
    setSelectsList([])
    let postDelData = {
      product_id: item.product_id,
      relation_id: item.relation_id,
      warehouse_area_id: item.warehouse_id,
      source_type: item.source_type,
      logistics_type: item.logistics_type,
      purchase_mode: item.purchase_mode,
      id: item.id
    }
    // 默认美国仓
    dispatch(delCartItemAsync(postDelData)).then((data) => {
      if (!data.error) {
        getConactList()
      }
    });
  }
  const priceObj = useMemo(() => {
    return getPriceObj(selects);
  }, [selects]);

  const actualPrice = useMemo(() => {
    return calcCartPrice(priceObj);
  }, [priceObj]);
  const handleClick = () => {
    setDrawerShow(true);
  };
  const [checkPrice, setCheckPrice] = useState(0)
  const [checkPriceChina, setCheckPriceChina] = useState(0)
  // 预售
  const [checkAdvanceSale, setAdvanceSale] = useState(0)
  const [showFlag, setShowFlag] = useState(0)
  // 加载状态
  const [loadingHtml, setLoadingHtml] = useState(false)
  const handleChangeCheck = (type) => {
    switch (type) {
      case 1:
        setCheckPrice(0)
        setCheckPriceChina(0)
        setAdvanceSale(0)
        setShowFlag(0)
        setTimeout(() => {
          // 重新获取默认数据
          handleGetAreaList()
        }, 800);
        // 选中商品状态
        setSelects([]);
        break;
      case 2:
        setLoadingHtml(true)
        setCheckPrice(1)
        setCheckPriceChina(1)
        setAdvanceSale(0)
        setTimeout(() => {
          // 获取合同商品数据
          getConactList()
          setLoadingHtml(false)
        }, 800);
        setShowFlag(1)
        break;
      default:
        break;
    }
  };
  // 获取合同商品数据
  const getConactList = () => {
    let postParam = {
      warehouse_area_id: 0,
      purchase_mode: 0,
      source_type: 2,
      logistics_type: 0,
    }
    getContractGoods(postParam).then((res) => {
      if (res) {
        setContactList(res.list)
      }
    })
  }

  // 默认获取数据
  const getDataList = (wareAreaFist_id) => {
    console.log('ddd',activeType)
    let routerType = searchParams.get('productType')
    let goodsData = { ...paramData }
    if (routerType == 1) {
      setCheckPrice(0)
      setCheckPriceChina(0)
      setAdvanceSale(0)
      if (activeType == 'ship') {
        let postParam = {
          warehouse_area_id: wareAreaFist_id ? wareAreaFist_id : goodsData.warehouse_area_id,
          purchase_mode: goodsData.purchase_mode,
          source_type: goodsData.source_type,
          logistics_type: 2,
        }
        goodsData.logistics_type = 2
        setParamData(goodsData)
        getList(postParam)
      } else if (activeType == 'freight') {
        let postParam = {
          warehouse_area_id: wareAreaFist_id ? wareAreaFist_id : goodsData.warehouse_area_id,
          purchase_mode: goodsData.purchase_mode,
          source_type: goodsData.source_type,
          logistics_type: 3,
        }
        goodsData.logistics_type = 3
        setParamData(goodsData)
        getList(postParam)
      } else {
        let postParam = {
          warehouse_area_id: wareAreaFist_id ? wareAreaFist_id : goodsData.warehouse_area_id,
          purchase_mode: goodsData.purchase_mode,
          source_type: goodsData.source_type,
          logistics_type: 1,
        }
        goodsData.logistics_type = 1
        setParamData(goodsData)
        getList(postParam)
      }
      setShowFlag(0)
    } else if (routerType == 2) {
      setCheckPrice(0)
      setCheckPriceChina(0)
      setAdvanceSale(0)
      let postParam = {
        warehouse_area_id: wareAreaFist_id ? wareAreaFist_id : goodsData.warehouse_area_id,
        purchase_mode: 2,
        source_type: goodsData.source_type,
        logistics_type: goodsData.logistics_type,
      }
      getList(postParam)
      setShowFlag(2)
    } else {
      // 如果没有路由判断的时候
      if (activeType == 'ship') {
        let postParam = {
          warehouse_area_id: wareAreaFist_id ? wareAreaFist_id : goodsData.warehouse_area_id,
          purchase_mode: goodsData.purchase_mode,
          source_type: goodsData.source_type,
          logistics_type: 2,
        }
        goodsData.logistics_type = 2
        setParamData(goodsData)
        getList(postParam)
        setShowFlag(0)
      } else if (activeType == 'freight') {
        let postParam = {
          warehouse_area_id: wareAreaFist_id ? wareAreaFist_id : goodsData.warehouse_area_id,
          purchase_mode: goodsData.purchase_mode,
          source_type: goodsData.source_type,
          logistics_type: 3,
        }
        goodsData.logistics_type = 3
        setParamData(goodsData)
        getList(postParam)
        setShowFlag(0)
      } else if (activeType == 'self') {
        let postParam = {
          warehouse_area_id: wareAreaFist_id ? wareAreaFist_id : goodsData.warehouse_area_id,
          purchase_mode: goodsData.purchase_mode,
          source_type: goodsData.source_type,
          logistics_type: 1,
        }
        goodsData.logistics_type = 1
        setParamData(goodsData)
        getList(postParam)
        setShowFlag(0)
      } else {
        // 合同商品
        setCheckPrice(1)
        setCheckPriceChina(1)
        setAdvanceSale(0)
        setShowFlag(1)
        // // 获取合同商品数据
        getConactList()
      }
    }
  }
  // 获取地址列表
  useEffect(() => {
    if (isLogin && drawerShow) {
      setSelects([]);
      handleGetAreaList()
    }
  }, [activeType, isLogin, drawerShow]);
  useEffect(() => {
    handleShowList(cartList);
    setSelectsBySource(cartList);
  }, [cartList]);

  return (
    <React.Fragment>
      {isLogin && (
        <Fab
          size="small"
          color="primary"
          sx={(theme) => ({
            position: "fixed",
            top: "50%",
            right: 16,
            transform: "translateY(-50%)",
            borderRadius: 2,
            width: 55,
            height: 55,
            [theme.breakpoints.down("md")]: {
              height: 36,
              width: 36,
            },
          })}
          onClick={handleClick}
        >
          <AddShoppingCart
            sx={(theme) => ({
              fontSize: 28,
              [theme.breakpoints.down("md")]: {
                fontSize: 24,
              },
            })}
          ></AddShoppingCart>
          {/* 悬浮的购物测样式 */}
          <Badge
            badgeContent={cartProductNum}
            sx={{
              position: "absolute",
              top: 0,
              right: 0,
              ".MuiBadge-badge": { backgroundColor: "#FFCE22", color: "#fff" },
            }}
          ></Badge>
        </Fab>
      )}
      <SwipeableDrawer
        open={drawerShow}
        anchor="right"
        onOpen={() => setDrawerShow(false)}
        onClose={() => setDrawerShow(false)}
      >
        <Stack
          direction={"column"}
          sx={(theme) => ({
            width: 500,
            height: "100%",
            position: "relative",
            [theme.breakpoints.down("sm")]: { width: 350 },
          })}
        >
          <Stack
            direction="column"
            justifyContent={"space-between"}
            sx={{ p: 1, pt: 0.5, flex: "0 0 auto", position: "relative" }}
          >

            <Typography variant="h6" sx={{ display: "flex", alignItems: "center" }}>
              <img src={caetImg} style={{ width: "20px", height: "20px", marginRight: "5px" }} />
              {formatMessage("title")}
            </Typography>
            <Box sx={{ display: "flex" }}>
              {/* 价格 */}
              {checkPrice == 0 && <Box sx={{
                minWidth: "230px", background: "#002EA6", textAlign: "center", borderRadius: "6px",
                paddingTop: "5px", paddingBottom: "5px", cursor: "pointer", marginRight: "10px", color: "#fff",
                marginTop: "10px", height: "36px"
              }}>{formatMsg('product.nation')}{formatMsg('product.price')}</Box>
              }
              {checkPrice == 1 && <Box sx={{
                minWidth: "230px", border: "1px solid  #CCCCCC", textAlign: "center", borderRadius: "6px",
                paddingTop: "5px", paddingBottom: "5px", cursor: "pointer", marginRight: "10px",
                marginTop: "10px",
                marginBottom: "10px",
                ":hover": {
                  border: "1px solid #002EA6",
                }
              }}
                onClick={() => handleChangeCheck(1)}
              >{formatMsg('product.nation')}{formatMsg('product.price')}</Box>
              }

              {/* 中国离岸价 */}
              {checkPriceChina == 0 && <Box sx={{
                minWidth: "230px", border: "1px solid  #CCCCCC", textAlign: "center", borderRadius: "6px",
                paddingTop: "5px", paddingBottom: "5px", cursor: "pointer", marginRight: "10px",
                marginTop: "10px",
                marginBottom: "10px",
                ":hover": {
                  border: "1px solid #002EA6",
                }
              }}
                onClick={() => handleChangeCheck(2)}
              >
                {formatMsg("product.us.fobNewTitle")}
              </Box>
              }
              {checkPriceChina == 1 && <Box sx={{
                minWidth: "230px", background: "#002EA6", textAlign: "center", borderRadius: "6px",
                paddingTop: "5px", paddingBottom: "5px", cursor: "pointer", marginRight: "10px", color: "#fff",
                marginTop: "10px",
                marginBottom: "10px",
              }}
              >
                {formatMsg("product.us.fobNewTitle")}
              </Box>
              }

              {/* 特价区购物车按钮 */}
            </Box>
            {/* {checkAdvanceSale == 0 && <Box sx={{
                minWidth: "120px", border: "1px solid  #CCCCCC", textAlign: "center", borderRadius: "6px",
                paddingTop: "5px", paddingBottom: "5px", cursor: "pointer", marginRight: "10px",
                marginTop: "10px",
                marginBottom: "10px",
                ":hover": {
                  border: "1px solid #002EA6",
                }
              }}
                onClick={() => handleChangeCheck(3)}
              > {formatMsg("product.special.price")}</Box>
              }
              {checkAdvanceSale == 1 && <Box sx={{
                minWidth: "120px", background: "#002EA6", textAlign: "center", borderRadius: "6px",
                paddingTop: "5px", paddingBottom: "5px", cursor: "pointer", marginRight: "10px", color: "#fff",
                marginTop: "10px",
                marginBottom: "10px",
              }}
              >{formatMsg("product.special.price")}</Box>
              } */}


            {checkPriceChina == 0 &&
              <Box>
                {/* 外部 */}
                {internal_user != 2 && checkPriceChina == 0 && <Stack spacing={1}>
                  {activeKeys.map((item) => (
                    <Button
                      key={item}
                      disableRipple
                      size={"small"}
                      variant={activeType === item ? "contained" : "outlined"}
                      onClick={() => setActiveType(item)}
                    >
                      {formatMsg(`warehouseType.${item}`)}
                    </Button>
                  ))}
                </Stack>
                }
                {/* 内部用户 */}
                {internal_user == 2 && <Stack spacing={1}>
                  {activeKeys2.map((item) => (
                    <Button
                      key={item}
                      disableRipple
                      size={"small"}
                      variant={activeType === item ? "contained" : "outlined"}
                      onClick={() => setActiveType(item)}
                    >
                      {formatMsg(`warehouseType.${item}`)}
                    </Button>
                  ))}
                </Stack>
                }
                <Box sx={{ marginTop: "5px" }}>
                  <Box
                    className="float_css"
                  >
                    <Autocomplete
                      value={params['areaAdress_id'] || null}
                      onChange={(e, val) => handleSelectWare({ areaAdress_id: val })}
                      sx={{ width: 240, textTransform: "capitalize", marginRight: "5px" }}
                      // onFocus={(e) => (e.target.value = "")}
                      size="small"
                      options={warehouseAreaArry}
                      disableClearable
                      openOnFocus
                      clearOnBlur
                      isOptionEqualToValue={(option, value) => option?.id?.toString() === value?.id?.toString()}
                      getOptionLabel={(option) => (option ? option.warehouse_name + '/' + option.area_name : "")}
                      renderInput={(params) => <TextField {...params} />}
                    ></Autocomplete>
                    {/* 商品类型 */}
                    <Autocomplete
                      value={paramsGoods.goos_type}
                      onChange={(e, val) => handleSelectGoodsType({ goos_type: val })}
                      sx={{ width: 240, textTransform: "capitalize", }}
                      // onFocus={(e) => (e.target.value = "")}
                      size="small"
                      options={goodsArry}
                      disableClearable
                      openOnFocus
                      clearOnBlur
                      isOptionEqualToValue={(option, value) => option?.id?.toString() === value?.id?.toString()}
                      getOptionLabel={(option) => (option ? formatMessage(option.name) : "")}
                      renderInput={(paramsGoods) => <TextField {...paramsGoods} />}

                    ></Autocomplete>
                  </Box>
                </Box>
              </Box>
            }
            <IconButton onClick={() => setDrawerShow(false)} sx={{ position: "absolute", top: 0, right: 5 }}>
              <CloseOutlined></CloseOutlined>
            </IconButton>
          </Stack>

          {/* 不是fob商品下面数据 */}
          {checkPriceChina !== 1 &&
            <Box sx={{ flex: 1, overflowY: "auto", borderTop: 1, borderBottom: 1, borderColor: "#bebebe" }}>
              {showList.length !== 0 &&
                showList.map((classItem) => {
                  return (
                    <Box key={classItem.id}>
                      <Stack alignItems="center" sx={{ background: "#e1e1e1" }}>
                        <FormControlLabel
                          sx={{ ml: 0, color: "primary.main", fontSize: 14, mr: 1 }}
                          control={
                            <Checkbox
                              onChange={(e) => handleClassSelect(classItem.id, e.target.checked)}
                              checked={!!selectStatus.classCheck[classItem.id]?.checked}
                              indeterminate={!!selectStatus.classCheck[classItem.id]?.indeterminate}
                              color="primary"
                            ></Checkbox>
                          }
                        ></FormControlLabel>
                        <React.Fragment>
                          <Stack alignItems="center" color="#002ea6">
                            <LocalShippingOutlined sx={{ mr: 1 }}></LocalShippingOutlined>
                            <Typography variant="body2" color="#101010">
                              {formatMessage("table.pickUp", { text: classItem.name })}
                            </Typography>
                          </Stack>
                        </React.Fragment>
                      </Stack>
                      {classItem.children.map((product, index) => (
                        <React.Fragment key={index}>
                          <Grid
                            container
                            alignItems={"center"}
                            sx={{
                              position: "relative",
                              borderBottom: 1,
                              borderColor: "#e0e0e0",
                              py: 2,
                              borderBottomWidth:
                                index === classItem.children.length - 1 || product.bom_list?.length > 0 ? 0 : 1,
                            }}
                          >
                            <Grid item xs="auto" sx={{ position: "relative" }}>
                              <Checkbox
                                checked={itemIsCheck(product)}
                                onChange={(e) => handleSelect(product, e.target.checked)}
                                color="primary"
                              ></Checkbox>
                            </Grid>
                            <Grid item xs sx={{ minWidth: 150 }}>
                              <Stack spacing={1} sx={{ flex: 1 }} alignItems="center">
                                <ImgBox
                                  src={product.preview}
                                  width={50}
                                  height={50}
                                  disabledScale
                                  sx={{ mx: 0, flex: "0 0 auto" }}
                                ></ImgBox>
                                <Typography variant="body2" fontSize={12} sx={{ wordBreak: "break-all" }}>
                                  {formatMsg("sku")}:{product.custom_sn || product.title}

                                </Typography>
                              </Stack>
                            </Grid>
                            <Grid item xs="auto" sx={{ pt: 1, pl: "42px" }}>
                              <Stack spacing={1} alignItems="center">
                                {/* 非fob商品进步器 */}
                                {product.source_type !== '2' && <NumInput
                                  value={product.amount}
                                  onChange={(val) => handleNumChange(val, product)}
                                ></NumInput>}

                                <Typography
                                  variant="body2"
                                  color="primary.main"
                                  fontWeight={700}
                                  sx={{ flex: "0 0 auto", minWidth: 80, textAlign: "center" }}
                                >
                                  {currency}
                                  {product.totalPrice}
                                </Typography>
                                <IconButton size="small" onClick={() => handleDel(product)}>
                                  <DeleteOutline />
                                </IconButton>
                              </Stack>
                            </Grid>
                            {product.bom_list.length > 0 && (
                              <Typography
                                sx={{
                                  background: "#ffa13b",
                                  height: 16,
                                  lineHeight: "16px",
                                  px: 1,
                                  position: "absolute",
                                  top: 0,
                                  left: 0,
                                  color: "#fff",
                                  borderBottomRightRadius: 8,
                                  fontSize: 12,
                                  zIndex: 99,
                                  ml: "0!important",
                                }}
                              >
                                {formatMsg("combination")}
                              </Typography>
                            )}
                          </Grid>
                          {product.bom_list.length > 0 &&
                            product.bom_list.map((bomItem, index) => (
                              <Stack
                                key={index}
                                spacing={1}
                                alignItems={"center"}
                                justifyContent={"flex-end"}
                                sx={{
                                  pl: "42px",
                                  pr: 2,
                                  py: 1,
                                  background: "#f0f0f0",
                                  border: 1,
                                  borderColor: "#e0e0e0",
                                  borderTop: 0,
                                }}
                              >
                                <ImgBox
                                  src={bomItem.preview}
                                  width={30}
                                  height={30}
                                  disabledScale
                                  disabledPointer
                                  sx={{ mx: 0, flex: "0 0 auto" }}
                                ></ImgBox>
                                <Typography variant="body2" color="#bbb" fontSize={12} sx={{ flex: 1 }}>
                                  {formatMsg("sku")}:{bomItem.custom_sn || bomItem.title}
                                </Typography>
                                <Typography variant="body2" color="#707070" sx={{ flex: "0 0 60px" }}>
                                  X{bomItem.amount}
                                </Typography>
                                <Typography variant="body2" color="#707070" sx={{ flex: "0 0 80px" }}>
                                  {currency}
                                  {bomItem.totalPrice}
                                </Typography>
                              </Stack>
                            ))}
                        </React.Fragment>
                      ))}
                    </Box>
                  );
                })}
              {showList.length === 0 && <Empty></Empty>}
            </Box>
          }
          {/* 不是合同商品(fob) */}
          {checkPriceChina !== 1 && <Stack direction={"column"} spacing={1} sx={{ px: 1, py: 2, flex: "0 0 auto" }}>
            <Stack justifyContent={"space-between"}>
              <Typography>{formatMsg("paymentText.totalItems")}</Typography>
              <Typography>
                + {currency}
                {priceObj.total}
              </Typography>
            </Stack>
            <Stack justifyContent={"space-between"}>
              <Typography>{formatMsg("cart.tHeader.Postage")}</Typography>
              <Typography>
                + {currency}
                {priceObj.postAgeTotal}
              </Typography>
            </Stack>
            <Stack justifyContent={"space-between"}>
              <Typography>{formatMsg("paymentText.discount")}</Typography>
              <Typography>
                - {currency}
                {priceObj.discount}
              </Typography>
            </Stack>
            <Stack justifyContent={"space-between"}>
              <Typography>{formatMessage("actualPayment")}</Typography>
              <Typography sx={{ color: "primary.main", fontSize: 16, fontWeight: 700 }}>
                {currency} {actualPrice}
              </Typography>
            </Stack>

            {/* 第一个默认美仓下单 */}
            {showFlag == 0 && <Button
              disabled={selects.length === 0}
              variant="contained"
              sx={{ display: "block", width: 1, mb: 1, mt: 1 }}
              onClick={handlePayment}
            >
              {formatMsg("btn.payment")}
            </Button>}
            {/* 第二个fob下单 */}
            {showFlag == 2 && <Button
              disabled={selects.length === 0}
              variant="contained"
              sx={{ display: "block", width: 1, mb: 1, mt: 1 }}
              onClick={handlePaymentSpecial}
            >
              {formatMsg("btn.payment")}
            </Button>
            }
          </Stack>
          }
          {/* fob商品数据或者合同商品数据 */}
          {checkPriceChina == 1 && <Box sx={{ flex: 1, overflowY: "auto", borderTop: 1, borderBottom: 1, borderColor: "#bebebe" }}>
            {contactLsit.length !== undefined && contactLsit.length > 0 &&
              <Box sx={{ width: "98%", margin: "0 auto", marginTop: "5px" }}>
                {contactLsit.length !== 0 &&
                  contactLsit.map((item, index) => {
                    return (
                      <Accordion expanded={expanded === index} onChange={handleChangeAccordion(index)}>
                        <AccordionSummary
                          expandIcon={<ExpandMoreIcon />}
                        >
                          <Box sx={{ display: "flex", alignItems: "center" }}>
                            <Box sx={{ border: "1px solid #ccc", width: "50px", height: "50px", marginRight: "10px" }}>
                              <img src={item.merchant_logo} style={{ width: "100%", height: "100%", }} />
                            </Box>
                            <Typography>{item.label}</Typography>
                          </Box>
                        </AccordionSummary>
                        <AccordionDetails sx={{ padding: "10px" }}>
                          {/* 下面商家数据 */}
                          {item.child.length !== 0 && <Box>
                            {item.child.map((itemChildren, index) => (
                              <Box className="newFob_css">
                                <FormControlLabel
                                  sx={{ ml: 0, color: "primary.main", fontSize: 14, mr: 1 }}
                                  control={
                                    <Checkbox
                                      onChange={(e) => handleSelectItem(e.target.checked, itemChildren, index)}
                                      checked={selectsList.some(item => item.id === itemChildren.id)}
                                      color="primary"
                                    ></Checkbox>
                                  }
                                ></FormControlLabel>
                                <Box sx={{ border: "1px solid #ccc", width: "60px", height: "60px", }}>
                                  <img src={itemChildren.preview} style={{ width: "100%", height: "100%", }} />
                                </Box>
                                {/* ksu */}
                                <Box className="new_title_css">
                                  <Typography>{formatMsg("sku")}:{itemChildren.custom_sn}</Typography>
                                </Box>
                                <Box sx={{ marginRight: "10px" }}>
                                  {formatMsg("qty")}:
                                  {itemChildren.amount}
                                </Box>

                                {/* 合同商品删除 */}
                                <IconButton size="small" onClick={() => handleNewFobDel(itemChildren)}>
                                  <DeleteOutline />
                                </IconButton>
                              </Box>
                            ))}
                          </Box>
                          }
                        </AccordionDetails>
                      </Accordion>
                    );
                  })}
              </Box>
            }

            {contactLsit.length == 0 && <Empty></Empty>}
          </Box>
          }
          {/* 合同商品(fob) */}
          {checkPriceChina == 1 && <Stack direction={"column"} spacing={1} sx={{ px: 1, py: 2, flex: "0 0 auto" }}>
            {/* 合同商品下单按钮 */}
            {showFlag == 1 && <Button
              disabled={selectsList.length == 0}
              variant="contained"
              sx={{ display: "block", width: 1, mb: 1, mt: 1 }}
              onClick={handlePaymentFob}
            >
              {formatMsg("btn.CreateContractOrder")}
            </Button>
            }
          </Stack>
          }
          {(loading || loadingHtml) && (
            <Box
              sx={{
                textAlign: "center",
                height: "100%",
                position: "absolute",
                width: 1,
                background: "rgba(0, 0, 0, 0.1)",
              }}
            >
              <CircularProgress
                sx={{ position: "absolute", top: "50%", left: "50%", transform: "translateY(-50%);translateX(-50%)" }}
              ></CircularProgress>
            </Box>
          )}

        </Stack>
      </SwipeableDrawer>

      {/* 订购合同 */}
      <ContractAddressDialog {...orderData} close={handleContractClose} />
    </React.Fragment>
  );
};

export default FloatCart;
