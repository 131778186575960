import { Collapse, ListItemButton, ListItemText, List, ListItemIcon, SwipeableDrawer, Box } from "@mui/material";
import React, { useState } from "react";
import { Article, CreditCardOutlined, SwapHorizontalCircleOutlined } from "@mui/icons-material";
import { useLocation, useNavigate } from "react-router-dom";
import { useEffect, useMemo } from "react";
import { useFormate } from "../../hooks/useFormate";
import "./style/MenuList.scss";

import orderIcon from '../../assets/img/logistics/order.png'
import orderActiveIcon from '../../assets/img/logistics/order-a.png'
import transferIcon from '../../assets/img/logistics/transfer.png'
import transferActiveIcon from '../../assets/img/logistics/transfer-a.png'
const useListData = () => {
  //左侧列表
  const listData = [
    // 订单
    { 
      key: "order",
      title: "logistics.order",
      icon: orderIcon,
      active_icon: orderActiveIcon
    },
    // 交易合同
    {
      key: "deal_log",
      title: "logistics.deal",
      icon: transferIcon,
      active_icon: transferActiveIcon
    },
  ];
  const handleActiveKeys = (listData) => {
    listData.forEach((item) => {
      if (item.children) {
        item.activeKeys = item.children.map((o) => o.key);
        item.children = handleActiveKeys(item.children);
      } else {
        item.activeKeys = [item.key];
      }
    });
    return listData;
  };
  const list = useMemo(() => {
    return handleActiveKeys(listData);
  }, [listData]);
  return list;
};

const RenderItem = ({ source, open = [], level = 0, handleOpen }) => {
  const { pathname } = useLocation();
  const { formatMsg } = useFormate();
  return source.map((item) => {
    const isSub = !!item.children;
    const isOpen = open.indexOf(item.key) >= 0 ? true : false;
    const active = item.activeKeys.indexOf(pathname.replace("/logistics/", "")) >= 0;
    const color = active ? "#FF5E62" : "#323864";
    const subActiveSx =
      level === 0 && active
        ? {
            position: "relative",
            backgroundColor: "#FFF0F0",
            ":before": {
              content: '""',
              display: "block",
              height: "100%",
              width: "6px",
              position: "absolute",
              right: 0,
              top: 0,
              backgroundColor: "#FF5E62",
            },
            ":hover": {
              backgroundColor: "#FFF0F0",
            },
          }
        : {};
    return (
      <React.Fragment key={item.key}>
        <Box className="menu_list">
          <ListItemButton sx={level > 0 ? { pl: 6 * level } : subActiveSx} onClick={() => handleOpen(item.key)}>
            <img src={ active ? item.active_icon: item.icon } className="menu_icon" />
            <ListItemText
              primary={formatMsg(item.title)}
              primaryTypographyProps={{ sx: { color, fontSize: "16px" } }}
            ></ListItemText>
          </ListItemButton>
        </Box>
      </React.Fragment>
    );
  });
};

const MenuList = ({ drawerOpen, onClose = () => {} }) => {
  const listData = useListData();
  const { pathname } = useLocation();
  const [open, setOpen] = useState([]);
  const navigate = useNavigate();

  const handleOpen = (key) => {
    if (key.indexOf("sub") >= 0) {
      let _open = [...open];
      let index = _open.findIndex((o) => o === key);
      if (index >= 0) {
        _open.splice(index, 1);
      } else {
        _open.push(key);
      }
      setOpen(_open);
    } else {
      let path = "/logistics/" + key;
      if (pathname === path) return;
      onClose();

      navigate("/logistics/" + key);
    }
  };
  const initOpen = () => {
    let key = pathname.replace("/logistics", "");
    const findPaths = (source, id, paths = []) => {
      for (let i = 0; i < source.length; i++) {
        const tempPath = [...paths];
        tempPath.push(source[i].key);
        if (source[i].key === id) return tempPath;
        if (source[i].children) {
          const result = findPaths(source[i].children, id, tempPath);
          if (result) return result;
        }
      }
    };

    const pathArr = findPaths(listData, key) || [];
    setOpen(Array.from(new Set([...pathArr, ...open])));
  };
  useEffect(() => {
    initOpen();
  }, [pathname]);
  return (
    <React.Fragment>
      <SwipeableDrawer
        open
        onOpen={() => {}}
        onClose={() => {}}
        variant="permanent"
        sx={{
          "& .MuiDrawer-paper": { boxSizing: "border-box", position: "inherit", borderRight: 0 },
        }}
      >
        <List>
          <RenderItem source={listData} open={open} handleOpen={handleOpen}></RenderItem>
        </List>
      </SwipeableDrawer>
    </React.Fragment>
  );
};
export default MenuList;
