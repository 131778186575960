import request from "../../utils/request";
// 新版接口
import requestErp from "../../utils/requestErp";

export const fetchNewMessage = () => {
  return request.post("/supply/member/message/new");
};

export const getSearchData = (data) => {
  return requestErp.post("/home/skuList",data);
};