import FingerprintJS from "@fingerprintjs/fingerprintjs";
import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { fetchAccount, fetchInit, fetchMenu, fetchUserInfo, fetchVerify, toLogin, toRegister } from "./api";

const uuidKey = "uuid_str";
const tokenKey = "token";
const tokenErpKey = "token_erp";
const langKey = "active_lang";
const userType = "user_type";
const internalUser = "internal_user";
// 定义信息类型
const newType = 'new_type'
const userUuid = 'user_uuid'
const siteId = 'site_id'
// 包邮用户   is_free_delivery
const userPostage = 'user_postage'
// 新增零售商还是批发商用户类型
const customerType = 'customer_type'

// 共的运营商
const commonOperator = 'common_operator'
// 用户绑定私有库现在状态
// const userWarehouseType = 'user_warehouse_type'
const isFreight = "is_freight";//承包运费用户 1=否  2=是

const jumpToken = new URLSearchParams(window.location.search).get('token')
const token_erp = localStorage.getItem(tokenErpKey) || "";
const user_type = localStorage.getItem(userType) || ""; //用户类型 中还美
const internal_user = localStorage.getItem(internalUser) || ""; //用户类型内部还是外部
const customer_type = localStorage.getItem(customerType) || ""; //客户类型

// const user_warehouse_type = localStorage.getItem(userWarehouseType) || ""; 
const uuid = localStorage.getItem(uuidKey) || "";
const activeLang = localStorage.getItem(langKey) || "en-US";

const newTypeCk = localStorage.getItem(newType) || "";
const user_uuid = localStorage.getItem(userUuid) || ''
const site_id = localStorage.getItem(siteId) || ''
const user_postage = localStorage.getItem(userPostage) || ''
const commonOperatorObject = {
  // LA
  client_id: "05947269488",
  client_secret: "A5rwafSC5jkscIkDVfDIuG43EO7EUqZO",
  // // CHI
  // client_id:"05947269411",
  // client_secret:"B5rwafSC5jkscIkDVfDIuG43EO7EUqZO",
  // //MD
  // client_id:"05947269422",
  // client_secret:"C5rwafSC5jkscIkDVfDIuG43EO7EUqZO",
  // // HOU
  // client_id:"05947269433",
  // client_secret:"D5rwafSC5jkscIkDVfDIuG43EO7EUqZO",
  // // SEA
  // client_id:"05947269444",
  // client_secret:"E5rwafSC5jkscIkDVfDIuG43EO7EUqZO",
  // //WSAC
  // client_id:"05947269455",
  // client_secret:"F5rwafSC5jkscIkDVfDIuG43EO7EUqZO",
  // //LIFESTYLE
  // client_id:"05947269466",
  // client_secret:"G5rwafSC5jkscIkDVfDIuG43EO7EUqZO",
};

// 初始化状态
const initialState = {
  token: '',
  token_erp,  //erp用户系统token
  user_type,  //用户类型 中国还是美国用户
  customer_type,//客户类型
  internal_user, //是否是内部用户
  // user_warehouse_type, //用户绑定私有库现在状态
  newTypeCk,  //新类型
  activeLang,
  uuid,
  currency: "$",
  languages: {},
  shop_sn: "",
  logo: "",
  initFinish: false,
  messageShow: false,
  messageInfo: {},
  menuList: [],
  loginLoading: false,
  userInfo: {},
  verifyInfo: {},
  accountInfo: {},
  withdrawal_handling: {},
  recharge_handling: {},
  logoutModal: false,
  // 公共运营商
  commonOperatorObject,
  user_uuid,

  // 物流中心
  logisticsData: {
    nickname: '',
    account: '',
    balance: 0,

    payDialog: false,
    pay_fee: 0,
    order_number: ''
  }
};

// 用于响应对应的异步请求
export const initAsync = createAsyncThunk("app/initData", async (amount, thunkApi) => {

  const { getState, dispatch } = thunkApi;
  const { setUUID, setInitData } = appSlice.actions;

  let data;
  if (getState().app.uuid) {
    const { list } = await fetchInit();
    data = list[0];
  } else {
    const fp = await FingerprintJS.load();
    const result = await fp.get();
    dispatch(setUUID(result.visitorId));
    const { list } = await fetchInit();
    data = list[0];
  }
  jumpToken && localStorage.setItem(tokenKey, jumpToken)
  localStorage.getItem(tokenKey) && await dispatch(fetchUserAsync());
  dispatch(setInitData(data));
  return await fetchMenu();
});
export const fetchAccountAsync = createAsyncThunk("app/loadAccount", async () => {
  return await fetchAccount();
});
export const fetchUserAsync = createAsyncThunk("app/fetchUserInfo", async () => {
  return await fetchUserInfo();
});
export const fetchVerifyAsync = createAsyncThunk("app/fetchVerify", async () => {
  return await fetchVerify();
});
export const loginAsync = createAsyncThunk("app/login", async (data) => {
  return await toLogin(data);
});
export const registerAsync = createAsyncThunk("app/register", async (data) => {
  return await toRegister(data);
});

export const appSlice = createSlice({
  // 下面app,根据name 定义设置数据
  name: "app",
  initialState,
  // 同步方法
  reducers: {
    setLogoutModal: (state, { payload }) => {
      state.logoutModal = payload;
    },
    loginOut: (state) => {
      localStorage.setItem(tokenKey, "");

      // 设置erp token
      localStorage.setItem(tokenErpKey, "")
      localStorage.setItem(userUuid, "");
      // 客户类型
      localStorage.setItem(customerType, '')
      // 存储站点id信息
      localStorage.setItem(siteId, "");
      // 清除用户包邮类型
      localStorage.setItem(isFreight, "")
      // 存储包邮用户信息
      localStorage.setItem(userPostage,'');
      state.token = "";
      // 退出登录  erp_token 为空
      state.token_erp = "";
      state.userInfo = {};
    },

    // 原有token登录
    setToken: (state, { payload }) => {
      // console.log('设置token',state)
      localStorage.setItem(tokenKey, payload);
      state.token = payload;
    },
    resetToken: (state) => {
      localStorage.setItem(tokenKey, "");
      state.token = "";
    },
    //  新增一个token_erp
    setErpToken: (state, { payload }) => {
      // console.log('设置token',state)
      localStorage.setItem(tokenErpKey, payload);
      state.token_erp = payload;
    },
    resetErpToken: (state) => {
      localStorage.setItem(tokenErpKey, "");
      state.token_erp = "";
    },
    //  保存用户信息
    setUserType: (state, { payload }) => {
      // console.log('用户类型',state,payload)
      localStorage.setItem(userType, payload);
      state.user_type = payload;
    },
    //  保存用户类型
    setInternalUser: (state, { payload }) => {
      localStorage.setItem(internalUser, payload);
      state.internal_user = payload;
    },
    setNewType: (state, { payload }) => {
      localStorage.setItem(newType, payload);
      state.newTypeCk = payload;
    },

    setActiveLang: (state, { payload }) => {
      localStorage.setItem(langKey, payload);
      state.activeLang = payload;
    },
    setMessageInfo: (state, { payload = {} }) => {
      state.messageInfo = typeof payload !== "object" ? { message: payload, type: "info" } : payload;
      state.messageShow = true;
    },
    closeMessageInfo: (state) => {
      state.messageShow = false;
    },
    setUUID: (state, { payload }) => {
      state.uuid = payload;
      localStorage.setItem(uuidKey, payload);
    },
    setInitData: (state, { payload }) => {
      state.currency = payload.currency;
      state.languages = payload.language;
      state.shop_sn = payload.shop_sn;
      state.title = payload.title;
      state.logo = payload.logo;
      state.withdrawal_handling = payload.withdrawal_handling;
      state.recharge_handling = payload.pay_handling;
      // document.title = payload.title;
    },
    setLogisticsData: (state, { payload }) => {
      state.logisticsData = payload
    }
  },
  // 添加额外的reducers,响应不同的action type
  extraReducers: (builder) => {
    builder
      .addCase(initAsync.fulfilled, (state, action) => {
        state.menuList = action.payload;
        state.initFinish = true;
      })
      .addCase(loginAsync.pending, (state) => {
        state.loginLoading = true;
      })
      .addCase(loginAsync.rejected, (state) => {
        state.loginLoading = false;
      })
      .addCase(loginAsync.fulfilled, (state, { payload }) => {
        state.loginLoading = false;
        state.token = payload.token;
        // 客户类型
        localStorage.setItem(customerType, payload.user_type)
        // 用户类型 1 美国用户 86 中国用户
        state.user_type = payload.mobile_country_code;
        localStorage.setItem(userType, payload.mobile_country_code)

        state.internal_user = payload.is_internal_user;
        localStorage.setItem(internalUser, payload.is_internal_user)
        // // 用户私有库类型
        // state.user_warehouse_type = payload.user_warehouse_type;
        // localStorage.setItem(userWarehouseType, payload.user_warehouse_type)

        // 添加新类型
        state.newTypeCk = payload.user_sn;
        localStorage.setItem(newType, payload.user_sn)

        // 测试erpToken  start
        state.token_erp = payload.token;
        // 设置本地缓存ERP_TOKEN
        localStorage.setItem(tokenErpKey, payload.token)
        // end
        localStorage.setItem(tokenKey, payload.token);
        // 设置 user_uuid

        state.user_uuid = payload.uuid;
        localStorage.setItem(userUuid, payload.uuid);

        // 存储站点id信息
        localStorage.setItem(siteId, payload.operator_id);
        // 存储包邮用户信息
        localStorage.setItem(userPostage, payload.is_free_delivery);
        // 存储登录的用户
        state.userInfo = payload

        //缓存承包运费用户属性1=否  2=是 dyw
        localStorage.setItem(isFreight, payload.is_freight)
        // localStorage.setItem(isFreight, 1)
      })
      .addCase(registerAsync.pending, (state) => {
        state.loginLoading = true;
      })
      .addCase(registerAsync.rejected, (state) => {
        state.loginLoading = false;
      })
      .addCase(registerAsync.fulfilled, (state, { payload }) => {
        state.loginLoading = false;
        if (!Array.isArray(payload)) { // 中国用户注册返回的是一个空数组

          // 用户类型
          state.user_type = payload.mobile_country_code;
          localStorage.setItem(userType, payload.mobile_country_code)

          state.internal_user = payload.is_internal_user;
          localStorage.setItem(internalUser, payload.is_internal_user)

          // 添加新类型
          state.newTypeCk = payload.user_sn;
          localStorage.setItem(newType, payload.user_sn)

          // 测试erpToken  start
          state.token_erp = payload.token;
          // 设置本地缓存ERP_TOKEN
          localStorage.setItem(tokenErpKey, payload.token)
          // end
          state.token = payload.token;
          localStorage.setItem(tokenKey, payload.token);
          // 设置 user_uuid

          state.user_uuid = payload.uuid;
          localStorage.setItem(userUuid, payload.uuid);
        }

      })
      .addCase(fetchUserAsync.fulfilled, (state, { payload }) => {
        if (payload === undefined || payload.login_status == 0) {
          localStorage.setItem(tokenKey, "");
          localStorage.setItem(tokenErpKey, "");
          state.token = "";
          state.userInfo = {};
        } else {
          // auth_status 新字段 --- certified_status 旧字段 重新赋值
          payload.certified_status = payload.auth_status
          state.userInfo = payload;
          state.verifyInfo = payload;
          state.token_erp = payload.token;
          // 设置本地缓存ERP_TOKEN
          localStorage.setItem(tokenErpKey, payload.token)
          // 客户类型
          localStorage.setItem(customerType, payload.user_type)
          // end
          state.token = payload.token;
          localStorage.setItem(tokenKey, payload.token);
        }
      })
      .addCase(fetchAccountAsync.fulfilled, (state, { payload }) => {
        state.accountInfo = payload;
      })
      .addCase(fetchVerifyAsync.fulfilled, (state, { payload }) => {
        if (payload) {
          payload.id = payload?.id ?? ''
          payload.auth_id = payload?.auth_id ?? ''
          // auth_status 新字段 --- certified_status 旧字段 重新赋值
          payload.certified_status = payload.auth_status
          payload.logo = payload.logo_image_id ? payload.logo_image_data : undefined
          payload.license = payload.licence_images_id ? payload.licence_images_data : undefined
          payload.front = payload.artificial_person_card_front_id ? payload.artificial_person_card_front_data : undefined
          payload.reverse = payload.artificial_person_card_reverse_id ? payload.artificial_person_card_reverse_data : undefined
          payload.wholesaleLicense = payload.permit_images_id ? payload.permit_images_data : undefined
          payload.company = payload.company_name
          payload.zip = payload.zip_code
          payload.email = payload.company_email
          payload.country = payload.country_name || ''
          payload.province = payload.province_name || ''
          payload.WholesaleLicenceNumber = payload.permit_sn
          payload.licenseCard = payload.licence_sn
          state.verifyInfo = payload;
        }
      });
  },
});

export const { setToken, resetToken, setErpToken, resetErpToken, loginOut, setActiveLang, setMessageInfo, closeMessageInfo, setLogoutModal, setUserType, setInternalUser, setCommonOperatorObject, setUserWarehouseType, setLogisticsData } =
  appSlice.actions;

export const selectMessageInfo = (state) => ({ info: state.app.messageInfo, show: state.app.messageShow });
export const selectLanguages = (state) => state.app.languages;
export const selectActiveLanguage = (state) => state.app.activeLang;
export const selectInitFinish = (state) => state.app.initFinish;
export const selectMenuList = (state) => state.app.menuList;
export const selectCurrency = (state) => state.app.currency;
export const selectIsLogin = (state) => !!state.app.token;
export const selectLoginLoading = (state) => state.app.loginLoading;
export const selectUserInfo = (state) => state.app.userInfo;
export const selectLogo = (state) => state.app.logo;
export const selectAccountInfo = (state) => state.app.accountInfo;
export const selectWithdrawalHandling = (state) => state.app.withdrawal_handling;
export const selectRechargeHandling = (state) => state.app.recharge_handling;
export const selectVerifyInfo = (state) => state.app.verifyInfo;
export const selectLogoutModal = (state) => state.app.logoutModal;
export default appSlice.reducer;
