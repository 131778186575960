import { Outlet } from "react-router-dom";
import React from "react";
import { useLocation } from "react-router-dom";
import { useEffect } from "react";
import { 
    Grid,
    Box
} from "@mui/material";
import BaseInfo from '../LogisticsCenter/BaseInfo'
import './style/LogisticsCenter.scss'
const LogisticsCenter = () => {
  const location = useLocation();
  useEffect(() => {
    window.scrollTo({ top: 0 });
    document.title = 'Logistics Freight Center'
  }, [location.pathname]);
  return (
    <React.Fragment>
      <Grid container spacing={2} sx={{
          backgroundColor: '#f7f7f7',
          minHeight: '100vh', 
          pl: 12, 
          pr: 2,
          flexFlow: 'nowrap'
        }}
        >
        <Grid item className="container_left">
            <BaseInfo></BaseInfo>
        </Grid>
        <Grid item className="container_right">
            <Outlet></Outlet>
        </Grid>
      </Grid>
    </React.Fragment>
  );
};

export default LogisticsCenter;
