import requestErp, { getParams } from "../../utils/requestErp";
export const uploadFile = (file) => {
  const params = getParams({ file_data: file, file_type: 1 });
  let formData = new FormData();
  Object.keys(params).forEach((key) => {
    formData.append(key, params[key]);
  });
  // 图片
  return requestErp.post("/utils/uploadFile", formData, {
    headers: { "content-type": "multipart/form-data" },
  });
};
