/**
 * @description 常用正则表达式
 * @author Ahao
 */

const validInteger = (val) => {
  if (!val) return
  const regex =  /(^[1-9]\d*$)/
  return regex.test(val)
}

/**
 * @description 验证金额（含零），保留两位小数(注：这里需要为string类型)
 */
const validMoney = (val) => {
  if (!val) return
  const regex = /(^[1-9]([0-9]+)?(\.[0-9]{1,2})?$)|(^(0){1}$)|(^[0-9]\.[0-9]([0-9])?$)/
  return regex.test(String(val))
}


/**
 * @description 验证邮箱规则
 */
const validEmail = (val) => {
  if (!val) return 
  const regex = /^([A-Za-z0-9_\-\.])+\@([A-Za-z0-9_\-\.])+\.([A-Za-z]{2,4})$/
  console.log(regex.test(val))
  return regex.test(val)
}


export {
  validInteger,
  validMoney,
  validEmail,
}
